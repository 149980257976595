import request from '../../utils/request';

const getCapacitance = () => {
  return request({
    url: '/api/report/report-wallet',
    method: 'POST',
    private: true,
  });
};
const getParticipants = () => {
  return request({
    url: '/api/report/prospection',
    method: 'POST',
    private: true,
  });
};

const ReportService = {
  getCapacitance,
  getParticipants,
};

export default ReportService;
