import request from '../../utils/request';

const getAll = () => {
  return request({
    url: '/api/dataset',
    method: 'POST',
    private: true,
  });
};

const DatasetService = {
  getAll,
};

export default DatasetService;
