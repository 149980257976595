import React from 'react';
import PropTypes from 'prop-types';
import './styles.less';
import { Skeleton } from 'antd';

const SummaryItem = ({ description, value, icon, loading }) => {
  return (
    <div className="summary-item">
      <div className="summary-item__title">
        {loading ? (
          <Skeleton.Input style={{ width: 100, height: 20, marginRight: 6 }} active />
        ) : (
          <h2>
            <strong>{value}</strong>
          </h2>
        )}
        {loading ? <Skeleton.Button size="small" loading={loading} shape="circle" active /> : icon}
      </div>

      {loading ? (
        <Skeleton.Input style={{ width: 100, height: 20, marginRight: 6 }} active />
      ) : (
        <p className="summary-item__description">{description}</p>
      )}
    </div>
  );
};

export default SummaryItem;

SummaryItem.propTypes = {
  description: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  loading: PropTypes.bool,
};

SummaryItem.defaultProps = {
  loading: false,
};
