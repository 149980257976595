import { Dropdown, Menu } from 'antd';

import { LogoutOutlined } from '@ant-design/icons';
import Avatar from 'antd/lib/avatar/avatar';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { removeToken } from '../../../../utils/auth';
import { BottomSelectArrowIcon, SmileIcon } from '../../../../assets/icons';
import { AppContext } from '../../../../app/AppContext';
import './styles.less';

const menu = ({ cleanUser }, router) => (
  <Menu className="header-menu">
    <Menu.Item
      key="0"
      onClick={() => {
        router.push('/cuenta/cambio-de-contraseña');
      }}
    >
      Cambiar contraseña
    </Menu.Item>
    <Menu.Divider />

    <Menu.Item
      key="3"
      onClick={() => {
        removeToken();

        cleanUser();

        router.push('/login');
      }}
    >
      <LogoutOutlined />
      Cerrar sesión
    </Menu.Item>
  </Menu>
);

const DropdownMenu = () => {
  const router = useHistory();
  const {
    user: { actions },
  } = useContext(AppContext);
  return (
    <Dropdown
      overlay={() => menu(actions, router)}
      trigger={['click']}
      className="dropdown-header"
      overlayClassName="dropdown-header-overlay"
    >
      <span className="ant-dropdown-link">
        <Avatar icon={<SmileIcon />} size={44} className="avatar-user" /> <BottomSelectArrowIcon />
      </span>
    </Dropdown>
  );
};

export default DropdownMenu;
