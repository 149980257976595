import { Col, Row } from 'antd';

import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { AppContext } from '../../../app/AppContext';
import { GraduationGatIcon } from '../../../assets/icons';
import CertificationIcon from '../../../assets/icons/Certification';
import CondecorationIcon from '../../../assets/icons/Condecoration';
import LibraryAddCheckIcon from '../../../assets/icons/LibraryAddCheck';
import LocalLibraryIcon from '../../../assets/icons/LocalLibrary';
import OutlineArrowCircleLeftIcon from '../../../assets/icons/OutlineArrowCircleLeft';
import OutlineSchoolIcon from '../../../assets/icons/OutlineSchool';
import ProspectionIcon from '../../../assets/icons/Prospection';
import ReportWalletIcon from '../../../assets/icons/ReportWallet';
import RoundAccessAlarmIcon from '../../../assets/icons/RoundAccessAlarm';
import CardImageText from '../../../components/CardImageText';
import IconDot from '../../../components/IconDot';
import SummaryItem from '../../../components/SummaryItem';

const CapacitanceReports = () => {
  const { functions } = useContext(AppContext);

  console.log('function del back ', functions);

  const router = useHistory();

  const reportList = [
    {
      key: 'REPORTE_CARTERA',
      component: (
        <CardImageText
          title="Reporte De Cartera"
          buttonText="Ver reporte"
          imageComponent={
            <IconDot icon={<ReportWalletIcon/>} size={32}/>
          }
          onClick={() => {
            router.push('/reportes/reporte-cartera');
          }}
          text={
            <ul style={{ paddingLeft: '16px' }}>
              <li> Resumen de excedentes</li>
              <li> Resumen de aportes</li>
            </ul>
          }
        />
      ),
    },
    {
      key: 'PROSPECCION',
      component: (
        <CardImageText
          title="Prospección"
          buttonText="Ver reporte"
          onClick={() => {
            router.push('/reportes/prospeccion');
          }}
          imageComponent={
            <IconDot icon={<ProspectionIcon />} size={32}/>
          }
          text={
            <ul style={{ paddingLeft: '16px' }}>
              <li>potencial</li>
            </ul>
          }
        />
      ),
    },
  ];
  const { capacitanceData, isDatasetLoading } = useContext(AppContext);

  console.log('capacitance data ', capacitanceData);

  return (
    <Row gutter={12} style={{ width: '100%' }}>
      <Col span={24}>
        <div
          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', marginBottom: '30px' }}
        >
          <OutlineArrowCircleLeftIcon style={{ marginRight: '6px' }} />
          <Link
            to="/"
            style={{ textAlign: 'center' }}
            onClick={() => {
              window.top.postMessage('goBackHome', '*');
            }}
          >
            Volver atrás
          </Link>
        </div>
      </Col>

      <Col xs={24} lg={{ span: 9 }}>
        <h2>
          {' '}
          <strong>Vista <b>OTIC</b></strong>
        </h2>
        <p>
          Aqui encontrarás el reporte por cartera e información de prospección de clientes
        </p>
      </Col>

      <Col xs={24} lg={{ span: 15 }}>
        <Row gutter={6}>
          {functions.map((f) => {
            const report = reportList.find((r) => r.key === f.key);

            return (
              <Col xs={24} md={{ span: 14 }} lg={{ span: 8 }} style={{ marginBottom: '75px', marginLeft: '20px' }}>
                {report.component}
              </Col>
            );
          })}
        </Row>
      </Col>
    </Row>
  );
};

export default CapacitanceReports;
