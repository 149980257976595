import { Col, message, notification, Row } from 'antd';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';

import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { AppContext } from '../../../../app/AppContext';
import OutlineArrowCircleLeft from '../../../../assets/icons/OutlineArrowCircleLeft/icon';
import ReportService from '../../../../service/ReportService';

import './style.less';

const CapacitanceReport = () => {
  const { isAllDataLoaded, user } = useContext(AppContext);
  const router = useHistory();
  const [embedConfig, setEmbedConfig] = useState({
    type: 'report', // Supported types: report, dashboard, tile, visual and qna
    embedUrl: undefined,
    accessToken: undefined, // Keep as empty string, null or undefined
    settings: {
      panes: {
        filters: {
          expanded: false,
          visible: false,
        },
        pageNavigation: {
          visible: false,
        },
      },
    },
  });

  useEffect(() => {
    notification.info({
      description: (
        <>
          Estamos cargando la información,
          <br />
          esto podría demorar unos minutos
        </>
      ),
    });
  }, []);

  useEffect(() => {
    if (isAllDataLoaded) {
      ReportService.getCapacitance()
        .then(({ data }) => {
          const { result } = data;
          let filters = [];
          const values = [];
          if (user.enterprise) {
            values.push(user.enterprise.toString());
          }
          values.push(...user.enterprises.map((e) => e.rut.toString()));

          console.log('values', [
            ...values,
            ...user.holdings.map((h) => '-' + h.holdingCode.toString()),
          ]);
          if (!user.assignAllEnterprises) {
            filters = [
              {
                $schema: 'http://powerbi.com/product/schema#basic',
                target: {
                  table: 'empresa',
                  column: 'holding_empresa',
                },
                logicalOperator: 'Or',
                conditions: [
                  ...values,
                  ...user.holdings.map((h) => '-' + h.holdingCode.toString()),
                ].map((el) => ({ operator: 'Contains', value: el })),
                filterType: 0,
                requireSingleSelection: true,
              },
            ];
          }

          setEmbedConfig((values) => ({
            ...values,
            embedUrl: result.embedUrl,
            accessToken: result.accessToken,
            pageName: result.pageName,
            filters,
          }));
        })
        .catch((e) => {
          if (e.status === 401) {
            router.push('/');
          }
          message.error('Ocurrio un error al cargar la información, intente mas tarde.');
        });
    }
  }, [isAllDataLoaded]);

  return (
    <>
      <Row gutter={24}>
        <Col span={24}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
            }}
          >
            <OutlineArrowCircleLeft style={{ marginRight: '6px' }} />
            <Link to="/" style={{ textAlign: 'center' }}>
              Volver atrás
            </Link>
          </div>
          <br />

          <h2>
            <strong>Reporte De Cartera  </strong>
          </h2>
        </Col>
      </Row>
      <PowerBIEmbed embedConfig={embedConfig} cssClassName="report-style-class" />
    </>
  );
};

export default CapacitanceReport;
