import { HomeOutlined } from '@ant-design/icons';
import CapacitanceReports from '../domain/Reports/CapacitanceReports';
import CapacitanceReport from '../domain/Reports/CapacitanceReports/CapacitanceReport';
import ParticipantsReport from '../domain/Reports/CapacitanceReports/ParticipantsReport';

const routes = [
  // PRIVATE

  {
    path: '/',
    component: CapacitanceReports,
    exact: true,
    title: 'Reportes Cartera',
    breadcrumb: 'Reports',
    icon: <HomeOutlined />,
    roleAccess: 'all',
    showInMenu: true,
  },

  {
    path: '/reportes/reporte-cartera',
    component: CapacitanceReport,
    exact: true,
    title: 'Reportes Cartera',
    breadcrumb: 'Reports',
    icon: <HomeOutlined />,
    roleAccess: 'all',
    showInMenu: true,
  },
  {
    path: '/reportes/prospeccion',
    component: ParticipantsReport,
    exact: true,
    title: 'Reportes Prospección',
    breadcrumb: 'Reports',
    icon: <HomeOutlined />,
    roleAccess: 'all',
    showInMenu: true,
  },
  // {
  //   path: '/reportes/certificacion',
  //   component: CertificationReport,
  //   exact: true,
  //   title: 'Reportes Certificación',
  //   breadcrumb: 'Reports',
  //   icon: <HomeOutlined />,
  //   roleAccess: 'all',
  //   showInMenu: true,
  // },
];

export default routes;
